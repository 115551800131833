<template>
  <v-card id="market_watch" tile :width="!$store.state.isMobileView && !$store.state.isLapView ? '375' : !$store.state.isMobileView && $store.state.isLapView ? '400' : '100%'" class="mkWatch" right absolute @mouseleave="
      currentIndex = -1;isDropped = false;">
    <div class="d-flex align-center border-bottom-mwSearch">
      <customIcon  :name="'search'" class="mt-1 pl-2" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  /> 
      <v-text-field height="40" id="watch_list_Search_inp" class="fsize13" v-model="search" hide-no-data hide-details solo tile flat dense open-on-clear label="Search" @input="search ? search = search.toString().toUpperCase() : ''" @keyup.esc="onClickOutside"></v-text-field>
      <span v-if="search.length > 0" @click="search = ''" class="cursor" id="watch_list_search_close_icon"> <customIcon  :name="'close'" class="mt-2 pr-2" :width="'20'" :height="'20'" :color="$store.state.amogaColor"  />  </span>
      <span v-if="!loading" class="secondaryColor fsize14 pr-4" id="wtach_list_length">{{ watchList.length > 0 && watchList[tab].scrips ? watchList[tab].scrips.length : 0 }}/25</span>
    </div>
    <div class="height-2">
      <v-progress-linear :active="loading" :indeterminate="loading" rounded-lg height="2" color="blue"></v-progress-linear>
    </div>

    <!-- search list dropdown in mkWatch -->
    <v-sheet transition="fade-transition" tile class="searchContent" v-click-outside="onClickOutside" id="watch_list_search_sheet">
      <ul class="pl-0" id="watch_list_search_sheet_ul">
        <li
          class="
            searchlist
            px-2
            py-3
            body-2
            border-bottom-light
            d-flex
            align-center
            listParent
            justify-space-between
          "
          v-for="(item, index) in searchList"
          :key="index"
          :id="`${index}_watch_list_search_sheet_li`"
        >
         <div class="d-flex">
          <a :id="`${index}_watch_list_search_sheet_scrip_name`"
            @click="item.checked ? onClickOutside() : handleMultipleClick(item, item.checked ? 'del' : 'add', false)"
            class="fsize14 ml-1"
             :class="getHoverClass(item, 'a')"
            >{{
              item.exch == "NSE" || item.exch == "BSE"
                ? item.symbol
                : item.formattedInsName
            }} &nbsp;
      <!-- <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
      <template v-slot:activator="{ on, attrs }"> -->
            <span  v-if="item.checked" title="Added">&#10004;</span>
          <!-- </template>
           <span>{{item.checked ? 'Added' : ''}}</span>
          </v-tooltip> -->
            <div
              class="fsize11 secondaryColor"
              v-if="item.exch == 'NSE' || item.exch == 'BSE'"
            >
              {{ item.formattedInsName }}
            </div>
            </a
          >
          <div
                class="hoverbtnsList"
              >
                <div class="d-flex justify-end">
                  <div class="d-flex align-center absolute">
                    <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                      <template v-slot:activator="{ on, attrs }">
                        <label
                          class="hoverBtn mr-2 buy"
                          v-if="item.exchange_segment.toString().toUpperCase() != 'INDEX'"
                          @click="
                            callOrderWindowByContract(
                              item,
                              'buy',
                              false,
                              'mkWatch',
                              false,
                              false,
                              false
                            )
                          "
                          :id="`${index}_watch_list_search_sheet_scrip_buy`"
                          v-bind="attrs"
                          v-on="on"
                          >B</label
                        >
                      </template>
                      <span>Buy</span>
                    </v-tooltip>

                    <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                      <template v-slot:activator="{ on, attrs }">
                        <label
                          class="hoverBtn mr-2 sell"
                          v-if="item.exchange_segment.toString().toUpperCase() != 'INDEX'"
                          @click="
                            callOrderWindowByContract(
                              item,
                              'sell',
                              false,
                              'mkWatch',
                              false,
                              false,
                              false
                            )
                          "
                           :id="`${index}_watch_list_search_sheet_scrip_sell`"
                          v-bind="attrs"
                          v-on="on"
                          >S</label
                        >
                      </template>
                      <span>Sell</span>
                    </v-tooltip>

                    <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                      <template v-slot:activator="{ on, attrs }">
                        <label :class="getHoverClass(item, 'add')"
                          class="hoverBtn mr-2 fsize16"
                         @click="item.checked ? onClickOutside() : handleMultipleClick(item, item.checked ? 'del' : 'add', false)"
                          v-bind="attrs"
                          v-on="on"
                           :id="`${index}_watch_list_search_sheet_scrip_check`"
                          > 
                         <span v-if="item.checked">&#10004;  </span> <span v-else> &#43; </span> 
                          </label
                        >
                      </template>
                      <span>{{item.checked ? 'Added' : 'Add'}}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
         </div>
          <button  :id="`${index}_watch_list_search_sheet_scrip_exch`"
            class="fsize10 pa-0 rounded min-width-40"
            :class="
              item.exch == 'NSE'
                ? 'buycolor'
                : item.exch == 'BSE'
                ? 'sellcolor'
                : item.exch == 'NFO'
                ? 'cancelled-msg'
                : 'cancelled-msg'
            "
            depressed
            min-width="36"
            max-width="36"
            height="26"
          >
            {{ item.exch }}
          </button>
        </li>
      </ul>
    </v-sheet>

    <div class="scrollContents" v-if="watchList.length > 0 && watchList[tab].scrips">
      <v-expansion-panels transition="fade-transition" :readonly="true" v-model="watchList[tab].panel" flat tile accordion multiple>
        <draggable class="w-100" @start="drag = true" :list="watchList[tab].scrips" @end="drag = false;sortList;">
          <v-expansion-panel tile class="ma-0 hoverparent" v-for="(item, index) in watchList[tab].scrips" :key="index" :id="`${index}_watch_list_scip_item`">
            <v-expansion-panel-header hide-actions @mouseenter="; 
                isDropped = false;
                currentIndex = index;" @click="$store.state.isMobileView ? callBottomSheet(item) : '' ">
              <div class="d-flex justify-space-between align-center pb-2" :data-font-size="tableFontSize">
                <span class="primaryColor" :id="`${index}_watch_list_scip_name`">{{
                    item.scripName
                  }}</span><span :id="`${index}_watch_list_scip_lp`" :class="parseFloat(item.PerChange) == 0 ? 'primaryColor' :
                      parseFloat(item.PerChange) > 0
                        ? 'positiveColor'
                        : 'negativeColor'
                    ">{{ item.ex == 'CDS' || item.ex == 'BCD' ? parseFloat(item.ltp).toFixed(4) : parseFloat(item.ltp).toFixed(2) }}</span>
              </div>
              <div class="d-flex justify-space-between align-center primaryColor" :sub-data-font-size="tableFontSize">
                <span class="secondaryColor d-flex align-center" :id="`${index}_watch_list_scip_exch`">{{ item.ex }} <span class="ml-1 " v-if="item.holFlag == 1" :id="`${index}_watch_list_scip_hold_flag`"> <customIcon  class="d-flex align-center" :name="'holdings'" :width="'12'" :height="'12'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />  </span> <span class="ml-1" v-if="item.holFlag == 1">{{item.holdQty}}</span></span>
                <span :id="`${index}_watch_list_scip_chg`" :class="parseFloat(item.PerChange) == 0 ? 'primaryColor' :
                      parseFloat(item.PerChange) > 0
                        ? 'positiveColor'
                        : 'negativeColor'
                    ">{{ parseFloat(item.Change).toFixed(2) }}
                  <span :id="`${index}_watch_list_scip_per_chg`">({{ item.PerChange }}%)</span></span>
              </div>
              <div class="hoverbtns" v-if="currentIndex == index && !$store.state.isMobileView">
                <div class=" d-flex justify-end" >
                <div class="d-flex align-center  absolute">
                  <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                      <label class="hoverBtn mr-2 buy" :id="`${index}_watch_list_scip_buy`" v-if="item.exSeg.toLowerCase() != 'index'" @click="callOrderWindowByContract(item ,'buy', false, 'mkWatch', false, false, false)" v-bind="attrs" v-on="on">B</label>
                    </template>
                    <span>Buy</span>
                  </v-tooltip>

                  <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                      <label :id="`${index}_watch_list_scip_sell`" class="hoverBtn mr-2 sell" v-if="item.exSeg.toLowerCase() != 'index'" @click="callOrderWindowByContract(item,'sell', false, 'mkWatch', false, false, false)" v-bind="attrs" v-on="on">S</label>
                    </template>
                    <span>Sell</span>
                  </v-tooltip>

                  <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                       <label :id="`${index}_watch_list_scip_chart`" class="hoverBtn mr-2" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" v-bind="attrs" v-on="on" @click="callTrandingViewChart(item, 'mkWatch')">
                      <customIcon class="hoverImg d-flex align-center"  :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                       </label>
                    </template>
                    <span>Chart</span>
                  </v-tooltip>

                  <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                     <label :id="`${index}_watch_list_scip_market_depth`"  class="hoverBtn mr-2" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" v-bind="attrs" v-on="on" @click="openPanel(index, item)" >
                      <customIcon  class="hoverImg d-flex align-center"   :name="'depth'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Market Depth</span>
                  </v-tooltip>

                  <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                       <label :id="`${index}_watch_list_scip_del`"  class="hoverBtn mr-2 " :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" v-bind="attrs" v-on="on" @click="addorDeleteScrip(item, 'del')">
                      <customIcon  class="hoverImg d-flex align-center"   :name="'delete'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <div style="width: 24px">
                    <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                      <template v-slot:activator="{ on, attrs }">
                         <v-btn :id="`${index}_watch_list_scip_opt`" @mouseover="getWindowHeight(`${index}_watch_list_scip_opt`)" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'"  v-if="currentIndex == index && item.exSeg.toLowerCase() != 'index'" @click="isDropped = !isDropped" min-width="30" max-width="35" height="25" outlined color="grey" depressed v-bind="attrs" v-on="on" class="hoverBtn d-flex align-center mr-2 text-capitalize fsize12">
                         <customIcon class="d-flex align-center"  :name="'more'" :width="'24'" :height="'24'"  :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                        </v-btn>
                      </template>
                      <span>More</span>
                    </v-tooltip>

                    <transition name="slide">
                      <ul :id="`${index}_watch_list_scip_ul`" :class="isRotate ? 'bottom25' : ''" v-if="currentIndex == index && isDropped" class="pa-0 list mkWatchMoreList alignDropDown">
                        <li :id="`${index}_watch_list_scip_alert_li`" @click="$store.commit('order/setIsalertDialog', true);$store.commit('order/setEditAlert', {'ltp':item.ltp , 'scripName': item.scripName, 'token' : item.token, 'exch' : item.ex});$store.commit('order/setAlertFrom', 'Mw');
                              isDropped = false;
                            " class="primaryColor fsize12 cursor">
                          Alert
                        </li>
                        <li :id="`${index}_watch_list_scip_info_li`" @click="
                              isDropped = false;
                              getInfo(item);
                            " class="primaryColor fsize12 cursor">
                          Info
                        </li>
                      </ul>
                    </transition>
                  </div>
                </div>
              </div>
              </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet class="mkdepth" v-if="depthExpand == watchList[tab].panel[index]">
                   
                   <div flat class="d-flex align-center px-2 pt-2 pb-1">
      <div class="w-25 text-center" :id="`${index}_watch_list_scip_o_div`">
        <p class="mb-1 fsize12  secondaryColor" :id="`${index}_watch_list_scip_o_label`">Open</p>
        <p class="mb-1 fsize12 primaryColor" :id="`${index}_watch_list_scip_o_inp`">{{setTofixed(formatNum(watchList[tab].scrips[index].o))}}</p>
      </div>
      <div class="w-25 text-center" :id="`${index}_watch_list_scip_h_div`">
        <p class="mb-1 fsize12  secondaryColor" :id="`${index}_watch_list_scip_h_label`">High</p>
        <p class="mb-1 fsize12 primaryColor" :id="`${index}_watch_list_scip_h_val`">{{setTofixed(formatNum(watchList[tab].scrips[index].h))}}</p>
      </div>
      <div class="w-25 text-center" :id="`${index}_watch_list_scip_l_div`">
        <p class="mb-1 fsize12  secondaryColor" :id="`${index}_watch_list_scip_l_label`">Low</p>
        <p class="mb-1 fsize12 primaryColor" :id="`${index}_watch_list_scip_l_val`">{{setTofixed(formatNum(watchList[tab].scrips[index].l))}}</p>
      </div>
      <div class="w-25 text-center" :id="`${index}_watch_list_scip_c_div`">
        <p class="mb-1 fsize12  secondaryColor" :id="`${index}_watch_list_scip_c_label`">Close</p>
        <p class="mb-1 fsize12 primaryColor" :id="`${index}_watch_list_scip_c_val`">{{setTofixed(formatNum(watchList[tab].scrips[index].c))}}</p>
      </div>
    </div>
    <div class="row ma-0 pa-2" v-if="watchList[tab].scrips[index].exSeg.toLowerCase() != 'index'">
      <table class="w-50 col-6 pa-0 buyTable wrap">
        <thead>
          <tr>
            <th class="font-weight-normal primaryColor pb-2 pr-4 fsize10 text-left" :id="`${index}_watch_list_scip_buy_bid_label`">BID</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" :id="`${index}_watch_list_scip_buy_orders_label`">ORDERS</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" :id="`${index}_watch_list_scip_buy_qty_label`">QTY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_bp1`">{{formatNum(watchList[tab].scrips[index].BPrice1)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bo1`">{{watchList[tab].scrips[index].BOrders1}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bq1`">{{watchList[tab].scrips[index].BQty1}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_bp2`">{{formatNum(watchList[tab].scrips[index].BPrice2)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bo2`">{{watchList[tab].scrips[index].BOrders2}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bq2`">{{watchList[tab].scrips[index].BQty2}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_bp3`">{{formatNum(watchList[tab].scrips[index].BPrice3)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bo3`">{{watchList[tab].scrips[index].BOrders3}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bq3`">{{watchList[tab].scrips[index].BQty3}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_bp4`">{{formatNum(watchList[tab].scrips[index].BPrice4)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bo4`">{{watchList[tab].scrips[index].BOrders4}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bq4`">{{watchList[tab].scrips[index].BQty4}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_bp5`">{{formatNum(watchList[tab].scrips[index].BPrice5)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bo5`">{{watchList[tab].scrips[index].BOrders5}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_bq5`">{{watchList[tab].scrips[index].BQty5}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="text-left fsize12 py-1 pr-4" :id="`${index}_watch_list_scip_buy_tot`">Total</td>
            <td colspan="2" class="text-right fsize12 py-1 pr-2" :id="`${index}_watch_list_scip_buy_tot_val`">{{ruppesFormat(formatNum(watchList[tab].scrips[index].totalbuyqty))}}</td>
          </tr>
        </tfoot>
      </table>
      <table class="w-50 col-6 pa-0 sellTable wrap">
        <thead>
          <tr>
            <th class="font-weight-normal primaryColor pb-2 pr-4 fsize10 text-left" :id="`${index}_watch_list_scip_sell_bid_label`">OFFER</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" :id="`${index}_watch_list_scip_sell_orders_label`">ORDERS</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" :id="`${index}_watch_list_scip_sell_qty_label`">QTY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_sp1`">{{formatNum(watchList[tab].scrips[index].SPrice1)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_so1`">{{watchList[tab].scrips[index].SOrders1}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_sq1`">{{watchList[tab].scrips[index].SQty1}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_sp2`">{{formatNum(watchList[tab].scrips[index].SPrice2)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_so2`">{{watchList[tab].scrips[index].SOrders2}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_sq2`">{{watchList[tab].scrips[index].SQty2}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_sp3`">{{formatNum(watchList[tab].scrips[index].SPrice3)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_so3`">{{watchList[tab].scrips[index].SOrders3}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_sq3`">{{watchList[tab].scrips[index].SQty3}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_sp4`">{{formatNum(watchList[tab].scrips[index].SPrice4)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_so4`">{{watchList[tab].scrips[index].SOrders4}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_sq4`">{{watchList[tab].scrips[index].SQty4}}</td>
          </tr>
          <tr>
            <td class="text-left fsize12 pb-1 pr-4" :id="`${index}_watch_list_scip_sp5`">{{formatNum(watchList[tab].scrips[index].SPrice5)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_so5`">{{watchList[tab].scrips[index].SOrders5}}</td>
            <td class="text-right fsize12 pb-1 pr-2" :id="`${index}_watch_list_scip_sq5`">{{watchList[tab].scrips[index].SQty5}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="text-left fsize12 py-1 pr-4" :id="`${index}_watch_list_scip_sell_tot`">Total</td>
            <td colspan="2" class="text-right fsize12 py-1 pr-2" :id="`${index}_watch_list_scip_sell_tot_val`">{{ruppesFormat(formatNum(watchList[tab].scrips[index].totalsellqty))}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="d-flex w-100 pa-2 fsize12" v-if="watchList[tab].scrips[index].exSeg.toLowerCase() != 'index'">
      <div class="w-50 pr-2">
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_v_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_v_label`">Volume</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_v_val`">{{ruppesFormat(formatNum(watchList[tab].scrips[index].volume))}}</div>
        </div>
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_ltq_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_ltq_label`">LTQ</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_ltq_val`">{{watchList[tab].scrips[index].lasttradedqty}}</div>
        </div>
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_expiry_div`"  v-if="watchList[tab].scrips[index].ex != 'NSE' && watchList[tab].scrips[index].ex != 'BSE'">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_expiry_label`">Expiry</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_expiry_val`">{{watchList [tab].scrips[index]['expDt']}}</div>
        </div>
         <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_lc_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_lc_label`">Lower Circuit</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_lc_val`">{{formatNum(watchList[tab].scrips[index].lc)}}</div>
        </div>
      </div>
      <div class="w-50">
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_avg_price_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_avg_price_label`">Avg.Price</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_avg_price_val`">{{watchList[tab].scrips[index].weightedavg}}</div>
        </div>
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_ltt_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_ltt_label`">LTT</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_ltt_val`">{{watchList[tab].scrips[index].lasttradedtime}}</div>
        </div>
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_oi_div`" v-if="watchList[tab].scrips[index].ex != 'NSE' && watchList[tab].scrips[index].ex != 'BSE'">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_oi_label`">OI</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_avg_price_val`">{{watchList[tab].scrips[index].openinterest}}</div>
        </div>
        <div class="d-flex justify-space-between" :id="`${index}_watch_list_scip_uc_div`">
          <div class="secondaryColor pb-1" :id="`${index}_watch_list_scip_uc_label`">Upper Circuit</div>
          <div class="primaryColor pb-1" :id="`${index}_watch_list_scip_uc_val`">{{formatNum(watchList[tab].scrips[index].uc)}}</div>
        </div>
      </div>
    </div>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>
    </div>

    <!-- No data Part -->
    <div class="scrollContents d-flex flex-column align-center justify-center" v-if="watchList.length > 0 && !watchList[tab].scrips" id="watch_list_no_data_div">
      <img width="50%" src="../assets/images/noData.svg" alt="noDataImg" class="mb-2" id="watch_list_no_data_img" />
      NO DATA FOUND
    </div>

    <!-- Footer Tabs -->
    <div class="border-top">
      <div class="footerTab border-top-midDark w-100" id="watch_footer_tab">
        <v-bottom-navigation class="justify-start" height="40" min-width="auto" max-width="auto" :value="tab" absolute mandatory tile color="primary white">
          <v-btn class="font-weight-bold border-right-dark" :id="`${index}_watch_footer_tab`" @change="changeTab(index)" active-class="activeMkBtn" max-width="60" min-width="48" height="40" v-for="(item, index) in 5" :key="index">
            {{ item }}
          </v-btn>
        </v-bottom-navigation>
        <v-menu transition="scroll-y-reverse-transition" left top :offset-y="true" min-width="100" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" v-bind="attrs" v-on="on" :id="`setting_watch_footer_tab_icon`" tile depressed active-class="activeMkBtn" class="float-right settingsIcon" max-width="60" min-width="48" height="40">
              <customIcon :name="'settings'" :width="'18'" :height="'18'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
            </v-btn>
          </template>
          <v-card class="pa-2 d-flex flex-column" height="150">
            <label class="fsize12 primaryColor mb-1" id="font_resize_label">Font Resize</label>
            <v-btn-toggle color="blue accent-4" class="mb-2 font-weight-bold" v-model="toggleBtns" mandatory dense>
              <v-btn class="fsize12" v-for="(item, index) in settingsList" :key="index" :id="`${index}_font_resize_btn`" @click="fontResizer(item)">{{ item }}</v-btn>
            </v-btn-toggle>
            <span class="fsize12 primaryColor mb-1" id="sort_by_label">Sort by</span>
            <v-btn-toggle color="blue accent-4" class="font-weight-bold" v-model="filterToggle" dense>
              <v-btn class="fsize12" v-for="(item, index) in filterList" :id="`${index}_sort_by_btn`" :key="index" @click="sortedArray(item.toLowerCase())">{{ item }}</v-btn>
            </v-btn-toggle>
          </v-card>
        </v-menu>
      </div>
    </div>

    <!-- Alert dialog -->

    <v-dialog v-model="alertDialog" width="500px">
      <v-expand-transition>
        <v-card class="pa-0">
          <div class="border-bottom-dark fsize16 font-weight-bold px-4 pt-4 pb-2">
            Security Trade Alert
          </div>
          <div class="px-3 pb-4">
            <div class="py-3">
              <div class="pb-2 d-flex justify-space-between">
                <div>
                  <span class="fsize13 primaryColor">
                    {{
                      alertData.scripName
                    }}
                  </span>
                  <span class="fsize9 secondaryColor ml-3">
                    {{ alertData.ex }}
                  </span>
                </div>
                <div class="fsize13 primaryColor">{{ alertData.ltp }}</div>
              </div>

              <div>
                <div @keypress.enter="alertSubscribe()">
                  <v-text-field type="number" v-model.number="alertPrice" label="Alert me when price reaches..." @keypress="keyPressAlphaNumeric($event)" min="0" outlined hide-details :required="false" dense class="mt-2 fsize13"></v-text-field>
                  <div class="red--text fsize12 mt-2 ml-2 h-16">
                    <span v-if="alertPrice == '' && submitted">Please Enter Price</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <v-btn height="32" min-width="80" depressed :loading="alertLoader" :disabled="alertLoader" :color="$store.state.buttonThemeColor" class="fsize14 text-capitalize white--text" @click="alertSubscribe()">Submit</v-btn>
              <v-btn height="32" min-width="80" depressed class="ml-2 fsize14 text-capitalize" outlined @click="$store.commit('marketWatch/setAlertDialog', false)">
                Cancel
              </v-btn>

            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- info dialog -->

    <v-dialog v-model="infoDialog" width="650px" transition="slide-x-reverse-transition">
      <v-card class="pa-5">
        <div class="d-flex align-baseline py-3">
          <div class="mx-2 fsize14" id="info_scrip_name">
            {{ this.currentInfoData.scripName }}
          </div>
          <div class="fsize9">{{ this.currentInfoData["ex"] }}</div>
          <div class="mt-1 fsize14 primaryColor orderalign" id="info_scrip_price_range">
            Price Range : {{ this.securityInfoData.LCircuitLimit }} -
            {{ this.securityInfoData.HCircuitLimit }}
          </div>
        </div>
        <v-divider class="mx-2 mb-2"></v-divider>
        <div class="">
          <div class="py-2">
            <div class="row ma-0">
              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_expiry_label">Expiry</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_expiry_val">
                    {{
                      securityInfoData.ExpiryDte != null
                        ? securityInfoData.ExpiryDte.split("-")[0]
                        : securityInfoData.ExpiryDte
                    }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_spot_price_label">Spot Price</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_spot_price_inp">
                    {{ this.currentInfoData["spotprice"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_tick_size_label">Tick Size</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_tick_size_inp">
                    {{ this.securityInfoData["TickSize"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_min_lot_label">Min Lot</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_min_lot_inp">
                    {{ this.securityInfoData["Blq"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_ltt_label">
                    Last Trading Date
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_ltt_inp">
                    {{ this.securityInfoData["LastTradingDate"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_start_data_label">Start Date</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_start_data_inp">
                    {{ this.securityInfoData["IsuStartDate"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_oi_label">Open Interest</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_oi_inp">
                    {{ this.securityInfoData["openinterest"] }}
                  </div>
                </div>
              </div>
              <v-divider vertical></v-divider>

              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_price_qua_label">Price Quatation</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_price_qua_val">
                    {{ this.securityInfoData["PriceQuatation"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_max_qua_label">
                    Max Quantity (in Lots)
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_max_qua_val">
                    {{ this.securityInfoData["MaxOrderSize"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_time_label">Time</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_time_val">
                    {{ this.securityInfoData["LTT"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_exchange_time_label">Exchange time</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_exchange_time_val">
                    {{ this.securityInfoData["LTT"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_end_date_label">End Date</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_end_date_val">
                    {{ this.securityInfoData["PriceQuatation"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_tot_trade_volume_label">
                    Total Trade Volume
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_tot_trade_volume_val">
                    {{ this.currentInfoData["TradeVolume"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_scrip_market_type_label">Market Type</div>
                  <div class="fsize12 primaryColor mb-2" id="info_scrip_market_type_val">
                    {{ this.securityInfoData["MarketType"] }}
                  </div>
                </div>
              </div>
              <div class="row pt-4 ma-0 d-flex justify-end">
                <v-btn depressed outlined class="  fsize14 text-capitalize" width="60px" height="32px" id="info_close_btn" @click="infoDialog = false">Close</v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <bottomsheet :currentData="currentSheetData" v-on:from-child ="getChildData"/>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import window from "../mixins/orderWindow";
import bottomsheet from '../components/bottomSheet.vue'
import customIcon from '../components/customIcon.vue'
import commonFunc from "../mixins/commonFunctions";
export default {
  mixins: [window,commonFunc],
  data: () => ({
    value: 0,
    search: "",
    depthExpand: -1,
    settingsList: ["S", "M", "L", "XL"],
    filterList: ["A-Z", "(%)", "LTP", "Exc"],
    tableFontSize: "M",
    toggleBtns: 1,
    filterToggle: "",
    infoDialog: false,
    currentInfoData: "",
    alertData: "",
    alertPrice: "",
    submitted: false,
    isDropped: false,
    currentIndex: -1,
    isRotate: false,
    currentSheetData: [],
     timerId: 0,
    tabTimer: 0,
    addScripTimer: 0
  }),

  components: { draggable, bottomsheet,customIcon },

  computed: {
    ...mapState("marketWatch", [
      "searchList",
      "securityInfoData",
      "loading",
      "alertLoader",
      "watchList",
    ]),
     ...mapActions("marketWatch", ["sortList"]),
    ...mapGetters("marketWatch", ["getAlertDialog"]),
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
      "getAuthorizeItem",
    ]),

    alertDialog: {
      get() {
        return this.getAlertDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("marketWatch/setAlertDialog", value);
        }
        return value;
      },
    },
    tab:{
      get(){
        return this.$store.state.marketWatch.tab
      },
      set(value){
              this.$store.commit("marketWatch/setTab", value) 
      }
    }
  },

  methods: {
    getHoverClass(item , key){
      var classcss
      if(key == 'a'){
        if(item.checked){
       classcss = this.$vuetify.theme.dark ? 'white--text' : 'color-282828'
      }else{
        classcss = this.$vuetify.theme.dark ? 'white--text' : 'color-282828'
      }
      return classcss
      }
      if(key == 'add'){
        if(item.checked){
       classcss = this.$vuetify.theme.dark ? 'darkHover' : ''
      }else{
        classcss = key
      }
      return classcss
      }

    },
    setTofixed(val) {
      let exchange = this.watchList[this.tab].scrips[this.depthExpand].ex;
      return exchange == "CDS" || exchange == "BCD"
        ? parseFloat(val).toFixed(4)
        : parseFloat(val).toFixed(2);
    },
    getChildData(data){
      if(data['page'] == 'MkWatch'){
        if(data['action'] == 'buy' || data['action'] == 'sell'){
          this.callOrderWindowByContract(data.item, data['action'], false, 'mkWatch', false, false, false)
        }
         if(data['action'] == "chart"){
          this.callTrandingViewChart(data.item, 'mkWatch')
        }
         if(data['action'] == 'depth'){
          const index = this.watchList[this.tab].scrips.indexOf(data.item)
          this.openPanel(index, data.item)
        }
         if(data['action'] == 'del'){
          this.addorDeleteScrip(data.item, 'del')
        }
         if(data['action'] == 'Alert'){
          this.$store.commit('order/setIsalertDialog', true);this.$store.commit('order/setEditAlert', {'ltp':data.item.ltp , 'scripName': data.item.scripName, 'token' : data.item.token, 'exch' : data.item.ex});this.$store.commit('order/setAlertFrom', 'Mw');
        }
         if(data['action'] == 'info'){
          this.getInfo(data.item)
        }
      }
    },
       callBottomSheet(val){
         this.openPanel(-1, "item")
         this.currentSheetData = []
      var tempData = {
        where: 'MkWatch',
        isOpen: true,
        item: val,
      }
      this.currentSheetData.push(tempData)
    },
   
    getWindowHeight(id) {
      
          var offsetHeight =
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 77
      var watchlistHeight = this.$store.state.windowHeight - 40
      let diff = watchlistHeight - offsetHeight
diff < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    onClickOutside() {
      this.search = "";
      this.$store.commit("marketWatch/setSearchList", []);
      clearTimeout(this.timerId);
      clearTimeout(this.addScripTimer)
    },
    async handleMultipleClick(data, type, isSingle){
      clearTimeout(this.addScripTimer)
      this.addScripTimer =  setTimeout(() => {
          this.addorDeleteScrip(data, type, isSingle) 
        }, 500);
    },
    async addorDeleteScrip(data, type, isSingle) {
      this.openPanel(-1, "item")
      var exchangeInput = data.exch;
      var isValidExchange = false;
      this.getUserSessionDto["exch"].forEach((element) => {
        if (element == exchangeInput) {
          isValidExchange = true;
        }
      });
      let addOrDel = {
        userId: this.getUserId,
        mwId: this.watchList[this.tab].mwId,
        scripData :[
        {
            exch :  data.exch ? data.exch : data.ex,
            token : data.token,
        }
        ],
      }
      
      if (type == "del") {
        this.$store.dispatch("marketWatch/deleteScripFromList", {addOrDel, data});
      } else if (type == "add") {
          this.$store.dispatch("marketWatch/addScripList", {addOrDel, data});
      }
      if (isSingle) {
        this.onClickOutside();
      }
    },

    /***
     * @author Senthil
     * Market depth open Close function
     ***/
    async openPanel(index, item) {
       (this.depthExpand > 0 && this.depthExpand != index) || index == -1 ? this.$store.dispatch("wsConnection/depthSubscription", '') : ''
       if(this.depthExpand != index){
         index >= 0 ? this.$store.dispatch("wsConnection/depthSubscription",item.ex + "|" + item.token) : ''
         this.depthExpand = index 
       }else{
        this.depthExpand = -1 
        this.$store.dispatch("wsConnection/depthSubscription", '')
       }
        
    },

    /***
     * @author Senthil
     * font Resize Method
     ***/
    fontResizer(value) {
      this.tableFontSize = value;
    },

    /***
     * @author Senthil
     * Date 04-12-2021
     * sorting market watch method
     ***/
    async sortedArray(sortType) {
      if(this.watchList[this.tab].scrips){
      this.activeFilterItem =
        this.activeFilterItem == ""
          ? sortType
          : sortType == this.activeFilterItem
          ? sortType == "a-z"
            ? "z-a"
            : sortType == "(%)"
            ? "despercentage (%)"
            : sortType == "ltp"
            ? "desltp"
            : sortType == "exc"
            ? "desexchange"
            : "a-z"
          : sortType;
      this.activeFilterItem == "a-z"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            a.scripName > b.scripName ? 1 : b.scripName > a.scripName ? -1 : 0
          )
        : this.activeFilterItem == "(%)"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            parseFloat(a.PerChange) > parseFloat(b.PerChange)
              ? 1
              : parseFloat(b.PerChange) > parseFloat(a.PerChange)
              ? -1
              : 0
          )
        : this.activeFilterItem == "ltp"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            parseFloat(a.ltp) > parseFloat(b.ltp)
              ? 1
              : parseFloat(b.ltp) > parseFloat(a.ltp)
              ? -1
              : 0
          )
        : this.activeFilterItem == "z-a"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            b.TradSym > a.TradSym ? 1 : a.TradSym > b.TradSym ? -1 : 0
          )
        : this.activeFilterItem == "despercentage (%)"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            parseFloat(b.PerChange) > parseFloat(a.PerChange)
              ? 1
              : parseFloat(a.PerChange) > parseFloat(b.PerChange)
              ? -1
              : 0
          )
        : this.activeFilterItem == "desltp"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            parseFloat(b.ltp) > parseFloat(a.ltp)
              ? 1
              : parseFloat(a.ltp) > parseFloat(b.ltp)
              ? -1
              : 0
          )
        : this.activeFilterItem == "desexchange"
        ? this.watchList[this.tab].scrips.sort((a, b) =>
            b.ex > a.ex ? 1 : a.ex > b.ex ? -1 : 0
          )
        : this.watchList[this.tab].scrips.sort((a, b) =>
            a.ex > b.ex ? 1 : b.ex > a.ex ? -1 : 0
          );
      this.openPanel(-1, "item");
      this.$store.commit('marketWatch/setWatchList', this.watchList);
      }
    },

    /***
     * @author Senthil
     * footer tabs
     ***/
    async changeTab(value) {
      this.tab = value
      this.onClickOutside();
      this.depthExpand = -1;
       clearTimeout(this.tabTimer)
      this.tabTimer = setTimeout(() => {
          this.subSribeWatch();
        }, 1000);
    },
    subSribeWatch(){
      this.$store.state.wsConnection.preDepthSubsription != ""
        ? this.$store.dispatch("wsConnection/depthSubscription", "")
        : "";
      this.$store.dispatch("marketWatch/checkSocketStatus");
    },
    getInfo(currentData) {
      this.$store.dispatch("marketWatch/getSecurityInfo", currentData);
      this.infoDialog = true;
      this.currentInfoData = currentData;
    },

    callalertDialog(val) {
      this.alertPrice = "";
      this.alertData = val;
      this.submitted = false;
      this.$store.commit("marketWatch/setAlertDialog", true);
    },

    async alertSubscribe() {
      this.submitted = true;
      if (this.alertPrice != "" && this.alertPrice != "") {
        await this.$store.dispatch("marketWatch/alertDialog", {
          data: this.alertData,
          alertPrice: this.alertPrice,
        });
      }
    },

    keyPressAlphaNumeric(event) {
      if (event.keyCode != 13) {
        var inp = String.fromCharCode(event.keyCode);
         if ((/[0-9.]/.test(inp) && !this.alertPrice.toString().includes('.')) ||  (/[0-9.]/.test(inp) && this.alertPrice.toString().includes('.') && this.alertPrice.toString().split('.')[1].length < 4)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
     callSearch(value) {
      var temp = {
        value: value.toString().toUpperCase(),
        where: "mwatch",
      };
      clearTimeout(this.timerId);
      this.$store.dispatch("marketWatch/getSearchScripts", temp);
    },
    listenMwTicks(event) {
      var tickerData = event.detail.storage;
       let token = this.$store.state.wsConnection.validToken
        if (this.watchList[this.tab]?.scrips && token) {
          var selectedArray = this.watchList[this.tab].scrips.filter((el) => {
        return el.token == token;
      });
      for(let element of selectedArray){
            var responseFeed = tickerData[element.token];
              element.ltp = responseFeed?.lp ? responseFeed?.lp : element.ltp;
              element.volume = responseFeed?.v
                ? responseFeed?.v
                : element.volume;
              element.PerChange = responseFeed?.pc
                ? responseFeed?.pc
                : element.PerChange;
              element.pdc = responseFeed?.c ? responseFeed?.c : element.pdc;
              element.Change = responseFeed?.lp
                ? responseFeed.lp - element.pdc
                : element.Change;
              element.l = responseFeed?.l ? responseFeed.l : element.l;
              element.h = responseFeed?.h ? responseFeed.h : element.h;
              element.o = responseFeed?.o ? responseFeed.o : element.o;
              element.c = responseFeed?.c ? responseFeed.c : element.c;
              if (
                this.depthExpand ==
                this.watchList[this.tab].scrips.indexOf(element)
              ) {
                element.openrate = responseFeed?.o
                  ? responseFeed.o
                  : element.openrate;
                element.highrate = responseFeed?.h
                  ? responseFeed.h
                  : element.highrate;
                element.lowrate = responseFeed?.l
                  ? responseFeed.l
                  : element.lowrate;
                element.previouscloserate = responseFeed?.c
                  ? responseFeed.c
                  : element.previouscloserate;
                element.BPrice1 = responseFeed?.bp1
                  ? responseFeed.bp1
                  : element.BPrice1;
                element.BOrders1 = !!responseFeed?.bo1
                  ? responseFeed.bo1
                  : element.BOrders1;
                element.BQty1 = !!responseFeed?.bq1
                  ? responseFeed.bq1
                  : element.BQty1;
                element.BPrice2 = responseFeed?.bp2
                  ? responseFeed.bp2
                  : element.BPrice2;
                element.BOrders2 = responseFeed?.bo2
                  ? responseFeed.bo2
                  : element.BOrders2;
                element.BQty2 = responseFeed?.bq2
                  ? responseFeed.bq2
                  : element.BQty2;
                element.BPrice3 = responseFeed?.bp3
                  ? responseFeed.bp3
                  : element.BPrice3;
                element.BOrders3 = responseFeed?.bo3
                  ? responseFeed.bo3
                  : element.BOrders3;
                element.BQty3 = responseFeed?.bq3
                  ? responseFeed.bq3
                  : element.BQty3;
                element.BPrice4 = responseFeed?.bp4
                  ? responseFeed.bp4
                  : element.BPrice4;
                element.BOrders4 = responseFeed?.bo4
                  ? responseFeed.bo4
                  : element.BOrders4;
                element.BQty4 = responseFeed?.bq4
                  ? responseFeed.bq4
                  : element.BQty4;
                element.BPrice5 = responseFeed?.bp5
                  ? responseFeed.bp5
                  : element.BPrice5;
                element.BOrders5 = responseFeed?.bo5
                  ? responseFeed.bo5
                  : element.BOrders5;
                element.BQty5 = responseFeed?.bq5
                  ? responseFeed.bq5
                  : element.BQty5;
                element.totalbuyqty = responseFeed?.tbq
                  ? responseFeed.tbq
                  : element.totalbuyqty;
                element.SPrice1 = responseFeed?.sp1
                  ? responseFeed.sp1
                  : element.SPrice1;
                element.SOrders1 = responseFeed?.so1
                  ? responseFeed.so1
                  : element.SOrders1;
                element.SQty1 = responseFeed?.sq1
                  ? responseFeed.sq1
                  : element.SQty1;
                element.SPrice2 = responseFeed?.sp2
                  ? responseFeed.sp2
                  : element.SPrice2;
                element.SOrders2 = responseFeed?.so2
                  ? responseFeed.so2
                  : element.SOrders2;
                element.SQty2 = responseFeed?.sq2
                  ? responseFeed.sq2
                  : element.SQty2;
                element.SPrice3 = responseFeed?.sp3
                  ? responseFeed.sp3
                  : element.SPrice3;
                element.SOrders3 = responseFeed?.so3
                  ? responseFeed.so3
                  : element.SOrders3;
                element.SQty3 = responseFeed?.sq3
                  ? responseFeed.sq3
                  : element.SQty3;
                element.SPrice4 = responseFeed?.sp4
                  ? responseFeed.sp4
                  : element.SPrice4;
                element.SOrders4 = responseFeed?.so4
                  ? responseFeed.so4
                  : element.SOrders4;
                element.SQty4 = responseFeed?.sq4
                  ? responseFeed.sq4
                  : element.SQty4;
                element.SPrice5 = responseFeed?.sp5
                  ? responseFeed.sp5
                  : element.SPrice5;
                element.SOrders5 = responseFeed?.so5
                  ? responseFeed.so5
                  : element.SOrders5;
                element.SQty5 = responseFeed?.sq5
                  ? responseFeed.sq5
                  : element.SQty5;
                element.totalsellqty = responseFeed?.tsq
                  ? responseFeed.tsq
                  : element.totalsellqty;
                element.volume = responseFeed?.v
                  ? responseFeed.v
                  : element.volume;
                element.lasttradedqty = responseFeed?.ltq
                  ? responseFeed.ltq
                  : element.lasttradedqty;
                element.lc = responseFeed?.lc
                  ? responseFeed.lc
                  : element.lc;
                element.lasttradedtime = responseFeed?.ltt
                  ? responseFeed.ltt
                  : element.lasttradedtime;
                element.weightedavg = responseFeed?.ap
                  ? responseFeed.ap
                  : element.weightedavg;
                element.openinterest = responseFeed?.oi
                  ? responseFeed.oi
                  : element.openinterest;
                element.uc = responseFeed?.uc
                  ? responseFeed.uc
                  : element.uc;
              }
            let index = this.watchList[this.tab].scrips.indexOf(element)
            this.watchList[this.tab].scrips[index] = element
          }
        }
      
    },
  },
  created() {
       var watchList = JSON.parse(localStorage.getItem('watchList'))
    watchList ? this.$store.commit("marketWatch/setWatchList", watchList) :''
  var watchTab = JSON.parse(localStorage.getItem('watchTab')) 
    var tab;
     watchTab ? tab = watchTab :  tab = 0
    this.changeTab(tab)
  },
  watch: {
    // search scrips in marketWatch
    search: function (newValue, oldValue) {
      clearTimeout(this.timerId);
      if (newValue && newValue.length > 1) {
        this.timerId = setTimeout(() => {
          this.callSearch(newValue);
        }, 500);
      }
    }
  },
};
</script>

<style scoped>
.mkWatch {
  position: fixed;
  right: 0;
  top: 56px;
  height: calc(100vh - 56px);
  resize: horizontal;
  border-left: 1px solid#a2a2a2 !important;
}

.scrollContents {
  height: calc(100vh - 138px);
  overflow-y: auto;
}

.activeMkBtn {
  background-color: #dee9fe !important;
  color: #0028ff !important;
  font-weight: bold !important;
}

.settingsBtnGroup {
  color: #170055 !important;
  background-color: #dfd3ff !important;
}

.theme--dark .activeMkBtn {
  background-color: #213b52 !important;
  color: #2992ec !important;
}

::v-deep .v-input__slot {
  box-shadow: none !important;
}

input[type="text"] {
  text-indent: 20px !important;
}

.searchlist {
  list-style: none !important;
}

.searchContent {
  z-index: 2;
  max-height: calc(100vh - 145px) !important;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background: white;
}

::v-deep .v-expansion-panel-header {
  display: block !important;
  padding: 8px 16px !important;
  transition: none !important;
  line-height: unset !important;
}

::v-deep .v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

::v-deep .v-expansion-panel {
  transition: none !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.theme--dark .v-expansion-panel {
  border-bottom: 1px solid #393939 !important;
}

.theme--dark .searchContent ul {
  background-color: #1e1e1e;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none !important;
}
/* ::v-deep .v-icon {
  font-size: 18px !important;
} */
::v-deep .v-item-group.v-bottom-navigation {
  width: 87% !important;
  box-shadow: none !important;
}
::v-deep .theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
}
/* tabs overwrite */
.mkWatch .theme--light.v-tabs .v-tab--active {
  opacity: 0.12 !important;
}
.footerTab {
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.settingsIcon {
  width: calc(100% - 50px);
}
.hoverImg {
  max-width: 60%;
}

.theme--dark .stsImglight {
  display: none;
}
.theme--light .stsImgdark {
  display: none;
}

/* font-resize class binding */
[data-font-size="S"] {
  font-size: 12px !important;
}
[data-font-size="M"] {
  font-size: 14px !important;
}
[data-font-size="L"] {
  font-size: 16px !important;
}
[data-font-size="XL"] {
  font-size: 18px !important;
}
[sub-data-font-size="S"] {
  font-size: 10px !important;
}
[sub-data-font-size="M"] {
  font-size: 11px !important;
}
[sub-data-font-size="L"] {
  font-size: 12px !important;
}
[sub-data-font-size="XL"] {
  font-size: 13px !important;
}
.searchScrip {
  color: #282828;
}
.height-2{height:2px !important;}
.v-expansion-panel-header:before{
  background-color: white !important;
}
</style>