<template>
    <v-dialog persistent v-model="isRiskDialog" width="50%" class="">
        <v-card class="">

            <div class="fsize18 font-bold pl-4 py-4 activeColor" id="message_title">
                {{ showData.messageTitle }} </div>
            <div class="px-4">
                <ul class="py-4 fsize14 " v-if="Array.isArray(showData.message)" id="message_ul">
                    <li v-for="(scrip, i) of showData.message" :key="i" style="list-style: inside !important;" :id="`message_${i}_li`">{{ scrip }}
                    </li>

                </ul>
                <div v-else class="py-4 fsize14 " id="source_content_div">{{ showData.message }}
                </div>

                <li class="fsize14 my-2" id="source_content_li" style="list-style: none !important;" v-if="showData.messageTitle == 'RISK DISCLOSURES ON DERIVATIVES' && showData.sourceExist == 1"> <strong>Source:</strong> SEBI study dated
                        January 25, 2023 on
                        <a href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
                            target="_blank" rel="noopener noreferrer">“Analysis of Profit and Loss of Individual
                            Traders dealing in equity Futures and Options
                            (F&O) Segment”,</a> wherein Aggregate Level
                        findings are based on annual Profit/Loss
                        incurred by individual traders in equity F&O
                        during FY 2021-22
                    </li>

                    <div class="py-4" v-if="showData.messageTitle == 'KRA Status' && showData.sourceExist == 1"> <a :href="showData.sourceLink" target="_blank" rel="noopener noreferrer" >{{ showData.sourceLink }}</a></div>
                    
                <!-- <li class="fsize14 my-2"  style="list-style: none !important;" 
                    @click="naviGateChange(showData)"> <a class="text-decoration-none">
                        {{ showData.sourceMsg }}</a>
                </li> -->

            </div>
            <v-row class="ma-0 d-flex justify-end pa-4 footerOrderWindow" v-if="showData.okExist == 1 || showData.cancelExist == 1">
                <v-btn depressed height="40" width="100" class="text-capitalize rounded activeColor"
                    @click="getData(showData, 'click', 'action')" v-if="showData.okExist == 1" :id="`${showData.okTitle}_btn`">{{ showData.okTitle }}</v-btn>
                <v-btn v-if="showData.cancelExist == 1" depressed outlined height="40" width="100" color="#9b9b9b"
                    class="text-capitalize secondaryColor rounded-sm-md cancelbtn ml-2" @click="getData(showData, 'click', 'click')" :id="`${showData.cancelTitle}_btn`">{{
                        showData.cancelTitle }}</v-btn>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'risk_disclosure',
    data() {
        return {
            showContent: [
                {
                    "message": [
                        "KRA not completed please click below mentioned link."
                    ],
                    "messageTitle": "KRA Status",
                    "userType": "individual",
                    "status": 1,
                    "okExist": 0,
                    "okTitle": "Declare Now",
                    "cancelExist": 0,
                    "cancelTitle": "Skip",
                    "sourceExist": 1,
                    "sourceMsg": "KYC",
                    "sourceLink": "https://kyc.trustline.in/",
                    "destination": "",
                    "displayType": "1"
                },
                {
                    "message": [
                        "9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.",
                        " On an average, loss makers registered net trading loss close to ₹ 50,000.",
                        " Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.",
                        " Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost."
                    ],
                    "messageTitle": "RISK DISCLOSURES ON DERIVATIVES",
                    "userType": "all",
                    "status": 1,
                    "okExist": 1,
                    "okTitle": "proceed",
                    "cancelExist": 0,
                    "cancelTitle": "cancel",
                    "sourceExist": 1,
                    "sourceMsg": "SEBI study dated January 25, 2023 on Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment, wherein Aggregate Level findings are based on annual Profit/Loss incurred by individual traders in equity F&O during FY 2021-22",
                    "sourceLink": "https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html",
                    "destination": "",
                    "displayType": "mandatory"
                },
            ],
            showData: '',
            showIndex: 0
        }
    },
    computed: {
        ...mapState(['notifyContent']),
        isRiskDialog: {
            get() {
                if (this.$store.state.isRiskDialog) {
          this.getData();
        }
                return this.$store.state.isRiskDialog
            },
            set(val) {
                this.$store.commit('setIsRiskDialog', val)
            }
        }
    },
    methods: {
        getData(showData, key, action) {
            key == 'click' ? this.showIndex += 1 : ''
            if(showData && showData.sourceExist == 0 &&key && key == 'click' &&action && action == 'action'){
                this.naviGateChange(showData)
            }
            if (!this.showData && this.notifyContent.length > 0) {
                this.showData = this.notifyContent[this.showIndex]
            }
            if (this.showData && this.notifyContent.length > this.showIndex) {
                this.showData = this.notifyContent[this.showIndex]
            } else {
                this.isRiskDialog = false
            }
            if (this.showData && this.showData.message && this.showData.message.length == 1) {
                this.showData.message = this.showData.message.toString()
            }
        },
        naviGateChange(data) {
            window.open(data.sourceLink, '_blank')
        }
    },
    mounted() {
        this.showData = "";
    this.showIndex = 0;
    this.getData();
    }
}
</script>