<template>
<!-- :class="{ slideUpCss: showOrderWindow && removeCss, slideDownCss: !showOrderWindow && !removeCss }" -->
<div>
  <form  id="orderWindowForm" class="d-flex flex-column" name="orderwindow" @submit.prevent="placeOrder()" @keyup.esc="hideOrderWindow()">
    <v-flex xs12 sm12 md4 lg6 xl6 class="orderWindow">
      <draggable>
        
        <template slot="header">
          <v-sheet class="headerclass" :class="orderType == 'buy' ? 'buyColor' : 'sellColor'" :style="$store.state.windowWidth < 330 ? 'height:auto !important' : ''">
            <v-row class="ma-0">
              <v-col class="pa-0">
                <div class="white--text fsize14 pb-1 text-capitalize" id="order_scrip_name">
                  {{
                    formetScripName
                  }}
                </div>

                <!-- nse bse Radio switch -->
                <v-radio-group :disabled="modifyOrder" id="order_radio_group" v-if="nseBseType" v-model="nseBseRadioSwitch" row class="orderWindowRadio pa-0 my-0 ml-0 d-flex align-center" height="24">
                 <span id="order_radio_group_nse" @click="nseBseRadioSwitch = 'NSE';nseBseSwitch('NSE')" class="fsize12 white--text d-flex align-center mr-2 cursor"> <customIcon style="height: 16px" class="mr-2" :name="nseBseRadioSwitch == 'NSE' ? 'radio-check' :'radio-blank'"  :width="'16'" :height="'16'" :color="'#ffffff'"  /> 
                  NSE: {{isNaN(nseSwitchPrice) ? 'NA' : nseSwitchPrice}} </span> 
                  <span id="order_radio_group_bse" @click="nseBseRadioSwitch = 'BSE';nseBseSwitch('BSE')" class="fsize12 white--text d-flex align-center cursor"> <customIcon style="height: 16px" class="mr-2" :name="nseBseRadioSwitch == 'BSE' ? 'radio-check' :'radio-blank'"  :width="'16'" :height="'16'" :color="'#ffffff'"/>BSE: {{isNaN(bseSwitchPrice) ? 'NA' : bseSwitchPrice}}</span>
                </v-radio-group>
                <div v-else class="white--text fsize14 d-flex">
                  {{ isNaN(getCurrentLtp) ? 'NA' : getCurrentLtp}}
                </div>

              </v-col>
              <v-col class="pa-0">
                <div class="d-flex justify-end align-start">
                  <div
                    class="showNudge cursor"
                    v-if="nudgeMessage && nudgeMessage.length > 0"
                  >
                  <span @click="$store.commit('orderWindow/setIsNudge', !isNudge)" id="nudge_info">
<customIcon class="d-flex align-center" :name="'info'" :width="'20'" :height="'20'" :color="'#ffffff'" />
                  </span>
                    
                    <span
                      ><div v-if="isNudge" class="test">
                        <div class="d-flex justify-end align-center">
                          <div class="popup nudgeli hoverNudge fsize14">
                            <div class="d-flex align-center justify-end mb-2">
                              <v-icon id="nudge_icon"
                                color="white"
                                size="16"
                                @click="
                                  $store.commit(
                                    'orderWindow/setIsNudge',
                                    !isNudge
                                  )
                                "
                                >mdi-close</v-icon
                              >
                            </div>
                            <ul class="list-flow pa-0"  id="nudge_dialog_ul_arrow">
                              <li
                                v-for="(list, index) in nudgeMessage"
                                :key="index"
                                :class="
                                  list.severity.toString().toLowerCase() ==
                                  'low'
                                    ? 'severity-low'
                                    : list.severity.toString().toLowerCase() ==
                                      'medium'
                                    ? 'severity-medium'
                                    : 'severity-high'
                                "
                                :id="`${index}_nudge_dialog_li_arrow`"
                              >
                                <span class="font-medium" :id="`${index}_nudge_msg`">{{ list.msg }}</span>
                                <a :id="`${index}_nudge_msg_link`"
                                  v-if="list.prompt"
                                  :href="list.prompt"
                                  class="text-decoration-none font-bold"
                                  target="_blank"
                                  >Read more.</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div></span
                    >
                  </div>
                  <div class="ml-4">
                  <v-switch id="order_switch" v-model="buySellRadio" :disabled="modifyOrder" dense @click="toggleOrderWindow()" hide-details inset class="orderSwitch ma-0" color="white"></v-switch>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </template>

        <template slot="main">
          <v-sheet class="border-bottom-light orderwindow">
            <!-- order type tabs -->
            <v-tabs id="order_window_tabs" :show-arrows="true" @change="$store.dispatch('common/setProdTypeCommon', 'change');showMore = false" v-model="orderTab" :color="orderType == 'buy' ? '#2992ec' : '#d13535'" dense max-width="30" class="orderWindowTab border-bottom-light">
              <v-tab :id="`${item}_tab`" :class="$vuetify.theme.dark ? 'color-ffffff' :'primaryColor'" :transition="false" :disabled="modifyOrder" v-for="(item, index) in orderTypeList" :key="index">
                <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item }}</span> </template><span class="fsize12">{{
                    item == "Regular"
                      ? "Regular order"
                      : item == "Cover"
                      ? "Cover order"
                      : item == "AMO"
                      ? "After Market Order (for the next day)"
                      : "Bracket order"
                  }}</span>
                </v-tooltip>
              </v-tab>
            </v-tabs>

            <v-tabs-items :transition="false"  class="px-2 py-2 border-bottom">
              <v-row class="ma-0 px-2 pb-3">
                <!-- priceBtns -->
                <v-col class="pa-0 mt-2">
                  <v-slide-group transition="none" v-model="prodType" mandatory class="pa-0 orderSlideGroup" hide-arrows>
                    <v-slide-item v-for="(item, i) in showproductItems" :key="i" v-bind:value="item" v-slot="{ active, toggle }">
                      <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @change="setProductTab()" depressed v-bind="attrs" v-on="on" :class="modifyOrder ? 'disabledSlideBtn' : ''" :color="
                              active
                                ? orderType == 'buy'
                                  ? 'buyColor'
                                  : 'sellColor'
                                : menuColors()
                            " height="26" min-width="54" class="fsize11 px-1 mr-2 rounded-sm orderProdBtn" @click="toggle" :id="`${item}_type`">
                            {{ item }}
                          </v-btn>
                        </template>
                        <span class="fsize12">{{
                          item == "MIS"
                            ? "Margin Intraday Squareoff: Requires lower margin. Has to be exited before market close."
                            : item == "CNC"
                            ? "CashNCarry: Longterm investment.Requires full upfront margin"
                            : item == "NRML"
                            ? "Normal: Carry forward positions overnight."
                            : "Margin Trading Facility."
                        }}</span>
                      </v-tooltip>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>
                <!-- priceBtns -->
                <v-col class="pa-0 mt-2 d-flex justify-end">
                  <v-slide-group transition="none" v-model="priceType" mandatory class="pa-0 orderSlideGroup" hide-arrows>
                    <v-slide-item v-for="(item, i) in showPriceItems" :key="i" v-bind:value="item" v-slot="{ active, toggle }">
                      <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="toggle" v-bind="attrs" v-on="on" depressed :color="
                              active
                                ? orderType == 'buy'
                                  ? 'buyColor'
                                  : 'sellColor'
                                : menuColors()
                            " height="26" min-width="54" class="ml-2 px-1 fsize11 rounded-sm text-capitalize" :id="`${item}_type`">
                            {{
                              item == "L"
                                ? "Limit"
                                : item == "MKT"
                                ? "Market"
                                : item
                            }}
                          </v-btn>
                        </template>
                        <span class="fsize12">{{
                          item == "L"
                            ? `${capitalizeFirstLetter(
                                orderType
                              )} at preferred price`
                            : item == "MKT"
                            ? `${capitalizeFirstLetter(
                                orderType
                              )} at Market price`
                            : item == "SL"
                            ? `${capitalizeFirstLetter(
                                orderType
                              )} at preferred price with a stoploss`
                            : `${capitalizeFirstLetter(
                                orderType
                              )} at market price with stoploss`
                        }}</span>
                      </v-tooltip>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>
              </v-row>

              <!-- input fields common -->
              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Quantity</label>
                  <div>
                    <v-text-field id="qty" ref="qty" @input="quantityValidation" class="orderInput min-w-150" :min="0" v-model.number="quantity" :step="minlot" @keypress="checkQuantity($event)" height="40" dense type="number" autofocus single-line outlined hide-details autocomplete="off"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Price</label>
                  <div>
                    <v-text-field id="price" class="orderInput min-w-150" @input="priceValidation" :disabled="!isPrice" :min="0" v-model.number="price" @keypress="decimalAllowed($event)" :step="tickPrice" height="40" dense type="number" single-line outlined hide-details >
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">{{ orderTypeList[orderTab] == 'Cover' ? 'SL Trigger Price' :'Trigger Price'}}</label>
                  <div>
                    <v-text-field id="triggerPrice" class="orderInput min-w-150" @input="triggerPriceValiation" :disabled="!isTrgPrice" :min="0"  v-model.number="triggerPrice" :step="tickPrice" @keypress="decimalAllowed($event)" height="40" dense type="number" single-line outlined hide-details ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              
              <!-- input fields for bracket order -->
              <v-row class="mx-0 mb-0 mt-2" v-if="this.orderTypeList[this.orderTab] == 'Bracket'">
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Target</label>
                  <div>
                    <v-text-field id="targetPrice" class="orderInput min-w-150" @input="targetPriceValidation" :min="0" v-model="targetPrice" :step="tickPrice" height="40"  @keypress="decimalAllowed($event)"  dense type="number" single-line outlined hide-details></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Stoploss</label>
                  <div>
                    <v-text-field id="stopLossPrice" class="orderInput min-w-150" @input="stopLossPriceValidation" :min="0" v-model="stopLossPrice" :step="tickPrice" height="40"  @keypress="decimalAllowed($event)" dense type="number" single-line outlined hide-details></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor">Trailing Stoploss</label>
                  <div>
                    <v-text-field id="trailStopLossPrice" class="orderInput min-w-150" @input="trailPriceValidation" :min="0" v-model="trailStopLossPrice" :step="tickPrice" height="40"  @keypress="decimalAllowed($event)" dense type="number" single-line outlined hide-details></v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row class="ma-0 pt-2 pl-3 line-height20 align-center" v-if="this.orderTypeList[this.orderTab] == 'Regular' || this.orderTypeList[this.orderTab] == 'AMO'">
                <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                  <template v-slot:activator="{ on, attrs }">
                    <label v-bind="attrs" v-on="on" class="More options cursor fsize12 user-select-none" @click="showMore = !showMore">More
                      <span class="chevron bottom"></span></label></template><span class="fsize12">More Options</span>
                </v-tooltip>
              </v-row>
              
              <!-- disclosed Qty field -->
              <v-row class="ma-0 d-flex justify-end align-center" v-if="showMore && (this.orderTypeList[this.orderTab] == 'Regular' || this.orderTypeList[this.orderTab] == 'AMO')">
                <v-col class="pa-0"></v-col>
                <v-col class="py-0 pl-0 pr-7 d-flex flex-column">
                  <label class="fsize12 line-height20">Validity</label>
                  <v-slide-group v-model="validityType" mandatory class="pa-0" width="178" hide-arrows>
                    <v-slide-item v-for="(item, i) in showValidityItems" :key="i" v-bind:value="item" v-slot="{ active, toggle }">
                      <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed v-bind="attrs" v-on="on" :color="
                              active
                                ? orderType == 'buy'
                                  ? 'buyColor'
                                  : 'sellColor'
                                : menuColors()
                            " height="26" min-width="54" class="fsize11 px-1 rounded-sm mr-2" :value="item" @click="toggle" :id="`${item}_type`">
                            {{ item }}</v-btn>
                        </template><span class="fsize12">{{
                          item == "DAY"
                            ? "Regular day order"
                            : "Immediate or Cancel"
                        }}</span>
                      </v-tooltip>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="4" class="px-2 py-0">
                  <label class="line-height20 fsize12 primaryColor" id="disclosed_qty_label">Disclosed Qty.</label>
                  <div>
                    <v-text-field id="discQty" class="orderInput min-w-150" @input="discQtyValidation" :min="0" v-model.number="disclosedQuantity" :disabled="!isDisclosedQty" height="40" dense type="number" single-line hide-details outlined></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-tabs-items>
          </v-sheet>
        </template>

        <!-- footer -->
        <template slot="footer">
          <v-sheet class="px-4 py-3 footerOrderWindow">
            <v-row class="ma-0">
              <v-col class="pa-0 d-flex align-center ma-0">
                <div class="d-flex ">
                  <div class="d-flex">
                    <div class="flex-column">
                    <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                  <template v-slot:activator="{ on, attrs }">
                 <span  @click="$store.commit('orderWindow/setBrokageDialog', true)" id="charges_val" class="ml-1 cursor fsize12 linkColor" v-bind="attrs" v-on="on">  Charges <span class="font-weight-bold">&#8377; {{ brokerageData.data ? ` ${ruppesFormat(parseFloat(brokerageData.data.total).toFixed(2))}` : `${ 'NA'}`}}</span></span> </template> <span class="fsize12">View charges & taxes </span> </v-tooltip>  
                  </div>
                   <span v-if="!brokerageLoader" class="d-flex align-center">
                  <v-tooltip max-width="200" top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                  <template v-slot:activator="{ on, attrs }">
                 <span  @click="$store.dispatch('orderWindow/getMarginAvailable', 'refresh');$store.dispatch('orderWindow/getBrokerageData')" class="ml-1 cursor" v-bind="attrs" v-on="on">  <customIcon class="d-flex"  :name="'refresh'" :width="'20'" :height="'20'" :color="'#ffffff'" /></span> </template> <span class="fsize12">Refresh </span> </v-tooltip>
                 </span>
                 <span v-else>
                  <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="brokerageLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
                 </span>
                  </div>
                </div>
                <!-- <div class="fsize12 secondaryColor d-flex">Margin required	&#8377; {{ruppesFormat(getAvailableMargin)}} <v-tooltip max-width="200" top color="toolTipColor toolTipTop">
                  <template v-slot:activator="{ on, attrs }">
                 <span @click="$store.dispatch('orderWindow/getMarginAvailable', 'refresh')" class="ml-1 cursor" v-bind="attrs" v-on="on">  <customIcon :class="marginLoader ? 'rotate' : ''" :name="'refresh'" :width="'20'" :height="'20'" :color="'#ffffff'" /></span> </template> <span class="fsize12">Refresh </span> </v-tooltip></div> -->
              </v-col>
              <v-col class="pa-0 d-flex justify-end">
                <v-btn :loading="loading" type="submit" id="order_window_btn" value="placeOrder" :disabled="loading" depressed height="40" width="100" class="text-capitalize rounded" :class="orderType == 'buy' ? 'buyColor' : 'sellColor'">{{  isBasket ? "Add" : modifyOrder ? "Modify" : orderType }}</v-btn>
                <v-btn depressed outlined height="40" width="100" color="#9b9b9b" class="
                    text-capitalize
                    secondaryColor
                    rounded-sm-md
                    cancelbtn
                    ml-2
                  " @click="hideOrderWindow" id="order_window_cancel_btn">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </template>

      </draggable>
    </v-flex>
  </form>
  <v-dialog v-model="nudgeDialog" width="550px" style="z-index: 999999999">
    <v-card class="pa-4 nudgeli">
      <ul class="list-flow pa-0" id="nudge_dialog_ul">
                              <li 
                                v-for="(list, index) in nudgeMessage"
                                :key="index"
                                :class="
                                  list.severity.toString().toLowerCase() ==
                                  'low'
                                    ? 'severity-low'
                                    : list.severity.toString().toLowerCase() ==
                                      'medium'
                                    ? 'severity-medium'
                                    : 'severity-high'
                                "
                                :id="`${index}_nudge_dialog_li`"
                              >
                                <span class="font-medium" :id="`${index}_nudge_dialog_li_msg`">{{ list.msg }}</span>
                                <a :id="`${index}_nudge_dialog_li_msg_link`"
                                  v-if="list.prompt"
                                  :href="list.prompt"
                                  class="text-decoration-none font-bold"
                                  target="_blank"
                                  >Read more.</a
                                >
                              </li>
                            </ul>

                            <v-row class="ma-0 d-flex justify-end pt-2" >
                <v-btn @click="callService" id="nudge_proceed_btn"
                  depressed
                  height="40"
                  width="100"
                  class="text-capitalize rounded buyColor"
                  >Proceed</v-btn
                >
                <v-btn id="nudge_cancel_btn"
                  depressed
                  outlined
                  height="40"
                  width="100"
                  color="#9b9b9b"
                  class="text-capitalize secondaryColor rounded-sm-md cancelbtn ml-2"
                  @click="nudgeDialog = !nudgeDialog"
                  >Cancel</v-btn
                >
            </v-row>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import orderWindowjs from "../mixins/orderWindow";
import customIcon from '../components/customIcon.vue'
import commonFunc from "../mixins/commonFunctions";
export default {
  mixins: [orderWindowjs,commonFunc],
  components:{
    customIcon
  }
};
</script>

<style>
.rotate{
    /* animation-name: ckw; */
    animation: ckw .5s infinite linear;
    /* animation-duration: .5s linear;
    animation-iteration-count: infinite; */
    
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.headerclass {
  cursor: move;
  padding: 12px 18px !important;
  background: #2992ec;
  height: 74px;
  /* width: 550px; */
  border-radius: 3px 3px 0 0 !important;
}
.orderWindowRadio .v-icon.v-icon {
  font-size: 16px !important;
  color: #ffffff !important;
}
.orderWindowRadio .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 12px !important;
}
.orderWindowRadio .v-input--selection-controls__input {
  width: 16px !important;
  height: 16px !important;
}
.orderWindowRadio .v-input--selection-controls__input {
  margin-right: 4px !important;
}
.orderWindowRadio .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 12px !important;
}
.orderWindowRadio .theme--light.v-label {
  color: #ffffff !important;
}
.orderWindowTab .v-tabs-bar {
  height: 38px !important;
}
.orderWindowTab .v-tab {
  font-size: 12px !important;
  text-transform: inherit !important;
  font-weight: normal !important;
  letter-spacing: inherit !important;
}
.prodTypeBtn {
  padding: 5px 8px;
  border-radius: 2px;
  font-size: 11px;
  width: 60px;
  height: 26px;
  text-align: center;
  color: #282828 !important;
  background-color: #e6e6e6;
  cursor: pointer;
}
.line-height20 {
  line-height: 20px !important;
}
.line-height24 {
  line-height: 24px !important;
}
.buyColor {
  background-color: #2992ec !important;
}
.sellColor {
  background-color: #f75723 !important;
}
.unActiveColor {
  background-color: #e6e6e6 !important;
}
.v-btn.buyColor,
.v-btn.sellColor {
  color: #ffffff !important;
  text-transform: capitalize !important;
}
.v-btn:not(.v-btn--outlined).unActiveColor {
  color: #282828 !important;
  text-transform: inherit !important;
}
.orderSlideGroup .v-slide-group__content > .v-btn.v-btn:nth-child(2) {
  margin-left: 8px !important;
}
.border-a8a8a8 {
  border: solid 1px #a8a8a8;
}
.orderField {
  width: 150px;
  height: 40px;
  outline: none !important;
  box-shadow: none !important;
}
.chevron::before {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0 !important;
  content: "";
  display: inline-block;
  height: 8px;
  left: 2px;
  position: relative;
  top: 2px;
  transform: rotate(-225deg);
  vertical-align: top;
  width: 8px;
  margin-top: 4px;
}
.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}
.orderWindow .v-btn {
  letter-spacing: 0 !important;
}
.v-input--selection-controls__ripple {
  height: 0px !important;
}
.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {
  height: 16px !important;
  width: 38px !important;
  top: calc(50% - 12px);
  left: -3px;
}
.v-input--switch.v-input--dense .v-input--switch__thumb {
  width: 12px !important;
  height: 12px !important;
}
.v-input--is-disabled.orderInput {
  background: url(../assets/images/strip.svg) !important;
  color: #a8a8a8 !important;
}
.v-input--is-focused:focus {
  border: #000000 !important;
}
.footerOrderWindow {
  background: #f3f3f3 !important;
}
.theme--dark .footerOrderWindow {
  background: #1e1e1e !important;
}
.pointer-events-none {
  pointer-events: none;
  cursor: disabled;
}
.orderProdBtn .v-slide-item--active.v-btn--disabled {
  background: #a7d3f9 !important;
  opacity: 0.5 !important;
}
.disabledSlideBtn {
  opacity: 0.5 !important;
  pointer-events: none;
}
button:disabled {
  cursor: not-allowed;
}
.theme--dark.v-sheet.orderwindow {
  background: #686868 !important;
}

.orderWindowTab .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #282828 !important;
}
.theme--dark .orderWindowTab .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #ffffff !important;
}
.slideDownCss {
  transition: transform 0.25s ease-out !important;
  animation-duration: 0.55s;
  -webkit-animation: fadeOutDown;
  animation: fadeOutDown;

  top: auto;
  bottom: 0px;
}
.slideUpCss {
  -webkit-animation: fadeInUp 0.25s ease;
  animation: fadeInUp 0.25s ease;
}

.orderSwitch .v-input--switch--inset .v-input--switch__track,
.v-input--switch--inset .v-input--selection-controls__input {
  width: 24px !important;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.orderWindowTab .v-tabs > .v-tabs-bar .v-tab--disabled .v-tab--active {
  color: unset !important;
}
.min-w-150{
  min-width: 150px;
}
@media (max-width: 1023px) {
  #draggable-container {
    left: 10% !important;
    top: 0 !important;
    display: block !important;
    width: auto !important;
  }
}
@media (max-width: 629px) {
  #draggable-container {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0;
    width: 100%;
    overflow-y: auto;
  }
}
.showNudge {
  position: relative;
  display: inline-block;
}

.hoverNudge {
  display: block;
  position: relative;
  z-index: 9999999;
  background-color: #333;
  color: #ffffff;
  border-radius: 4px;
}
.popup {
  background-color: #333;
  padding: 10px 14px;
  width: 550px;
  color: #fff;
  border-radius: 4px;
  text-align: left;
  position: absolute;
  right: -65px;
  bottom: 31px;
}
ul.list-flat,
ul.list-flat li {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}
.nudgeli li {
  list-style: none !important;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
  border-left: 4px solid #9b9b9b;
}
.nudgeli li.severity-low {
  border-left-color: #ff8f00;
}
.nudgeli li.severity-medium {
  border-left-color: #df514c;
}
.nudgeli li.severity-high {
  border-left-color: #df514c;
}
.test::after {
  content: "";
  position: absolute;
  right: 3px;
  top: -11px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: #333;
  border-bottom: 0;
  margin-bottom: -10px;
}
</style>