<template>
    <div>
        <!-- <v-btn @click="deleteDialog=true">click</v-btn> -->
        <v-dialog v-model="deleteDialog" id="alert_delete_dialog">
            <v-card class="px-5 py-4 d-flex flex-column justify-space-between" min-height="150px" min-width="400px">
                <!-- <div class="my-2">Delete</div> -->
                <div class="my-2" id="alert_delete_dialog_content">
                    Are you sure want to delete this <span class="font-weight-bold">{{ getCurrentDeleteAlertDetails.alertName }}</span> alert ?
                </div>
                <div class=" d-flex justify-end my-2">
                    <v-btn depressed :color="$store.state.buttonThemeColor" :loading="alertDeleteLoader" :disabled="alertDeleteLoader" class="text-capitalize mr-2 white--text"
                        @click="handleSubmit()" id="delete_confirm_btn"> Confirm </v-btn>
                    <v-btn depressed outlined olor="#f9f9f9" id="delete_close_btn" class="text-capitalize" @click="closeDialog()"> Cancel
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {

        }
    },
    props: {
        alertName: String
    },
    computed: {
        ...mapState('order', ['alertDeleteLoader']),
        ...mapGetters('order', ['getIsDeleteDialog','getCurrentDeleteAlertDetails']),
        deleteDialog: {
            get() {
                return this.getIsDeleteDialog
            },
            set(boolean) {
                this.$store.commit('order/setIsDeleteDialog', boolean)
            }
        },
    },
    methods: {
        closeDialog() {
            this.$store.commit('order/setIsDeleteDialog', false)
        },
        handleSubmit() {
            this.$store.dispatch('order/deleteAlertFromAPI', false)
        }
    },
}
</script>