<template>
    <v-navigation-drawer  v-model="brokageDialog"  absolute right temporary :width="brokageDialog ? '400' : '0'">
        <div class="row px-0 py-0  ma-0 w-100 position-absolute white--text" :class="orderType == 'buy' ? 'buyColor' : 'sellColor'">
          <div class=" ml-6 fsize14 pb-1 mt-2 text-capitalize w-100">
            Trustline - Brokerage Details 
          </div>
          <div class="ml-6 fsize16 pb-2 text-capitalize w-100">
            {{ formetScripName }} <span class="pl-4 fsize12">{{brokerageData.segment}}</span>
          </div>
        </div>
        <div  class="contentAlign">
          <div class="row px-6 pt-4 pb-0 ma-0 w-100">
            <div class="w-100 mb-3 fsize16 font-weight-bold primaryColor l-height-24">
                Transaction Overview
            </div>

            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.qty">
                <div>Quantity</div> <div>{{brokerageData.qty }}</div>
                <!-- {{(brokerageData.segment == "MCX" && brokerageData.instrumentType == "FUTCOM" || brokerageData.instrumentType == "OPTFUT") ? brokerageData.qty/brokerageData.lotSize : brokerageData.qty }} -->
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.price">
                <div>{{brokerageData.transactionType}} Price ({{priceType == 'L' ? 'Limit' : priceType == 'MKT' ? 'MARKET' : priceType}})</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.price).toFixed(brokerageData.segment == "CDS" || brokerageData.segment == "BCD" ? 4 : 2))}}</div>
            </div>
           <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>Exchange Turnover</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.turnover).toFixed(2))}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.type">
                <div>Order Type</div>  <div>{{brokerageData.type}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.segment">
                <div>Segment</div>  <div> {{brokerageData.segment == 'NSE' || brokerageData.segment == 'BSE' ? 'EQUITY' : brokerageData.instrumentType}} </div>
            </div>
        </div>
         <div class="row px-6 py-0 ma-0 w-100">
            <div class="w-100 my-3 fsize16 font-weight-bold primaryColor l-height-24">
                Charges breakdown
            </div>

            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>Brokerage <span class="text-capitalize">({{brokerageData.transactionType}})</span></div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.brokerage).toFixed(2))}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>STT/CTT</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.stt).toFixed(2))}}</div>
            </div>
            <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>Transaction Charges</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.transactionCharge).toFixed(2))}}</div>
            </div>
             <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>SEBI</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.sebi).toFixed(2))}}</div>
            </div>
             <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>Stamp Charges</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.stampCharges).toFixed(2))}}</div>
            </div>
             <div class="d-flex align-center justify-space-between w-100 fsize12 text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>GST</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.gst).toFixed(2))}}</div>
            </div>
             <div class="d-flex align-center justify-space-between w-100 fsize14 font-weight-bold text-capitalize primaryColor l-height-24" v-if="brokerageData.data">
                <div>Total</div> <div>&#8377; {{ruppesFormat(parseFloat(brokerageData.data.total).toFixed(2))}}</div>
            </div>
            
        </div>

        <div class="row pt-4 px-6 ma-0 w-100 primaryColor fsize12">
            # Information in Brokerage Calculator is approximate, not actual,
Refer to contract note emailed for exact prices, fees & charges for trades executed.

        </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import common from '../mixins/commonFunctions'
import customIcon from "../components/customIcon.vue";
export default {
  mixins: [common],
  data() {
    return {
    };
  },
  components: {
    customIcon,
  },
  computed:{
    ...mapState('orderWindow', ["brokerageData", 'formetScripName', 'orderType', 'priceType']),
    brokageDialog:{
        get(){
            return this.$store.state.orderWindow.brokageDialog
        },
        set(val){
            this.$store.commit('orderWindow/setBrokageDialog' , val)
        }
    }
  }
};
</script>

<style>
.contentAlign{
  margin-top: 64px;
  overflow-y: scroll;
}
.contentAlign::-webkit-scrollbar {
    width: 0px;
}
</style>